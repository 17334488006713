var PeopleMedia = PeopleMedia || {};
PeopleMedia.CP = PeopleMedia.CP || {};
PeopleMedia.CP.PhotoSearch = (function ($jq) {
    "use strict";

    var Oid = "PeopleMedia.CP.PhotoSearch";

    var _siteId = PeopleMedia.CP.SiteID();
    var _members = [];
    var _photoContainerId = "divPhotoContainer";
    var _photoTemplateId = "divPhotoTemplate";
    var _imageType = "s";
    var _gender = null;
    var _minAge = null;
    var _maxAge = null;
    var _postalCode = null;
    var _postalCodeRadius = null;
    var _countryCode = null;
    var _photoOnly = null;
    var _optedInOnly = null;
    var _resultCount = null;
    var _profileUrl = null;

    var SiteId = function(siteId) {
        if (arguments.length > 0) {
            _siteId = siteId;
        } else {
            return _siteId;
        }
    };

    var Members = function(members) {
        if (arguments.length > 0) {
            _members = members;
        } else {
            return _members;
        }
    };

    var PhotoContainerId = function(photoContainerId) {
        if (arguments.length > 0) {
            _photoContainerId = photoContainerId;
        } else {
            return _photoContainerId;
        }
    };

    var PhotoTemplateId = function(photoTemplateId) {
        if (arguments.length > 0) {
            _photoTemplateId = photoTemplateId;
        } else {
            return _photoTemplateId;
        }
    };

    var ImageType = function (imageType) {
        if (arguments.length > 0) {
            _imageType = imageType;
        } else {
            return _imageType;
        }
    };

    var Gender = function(gender) {
        if (arguments.length > 0) {
            _gender = gender;
        } else {
            return _gender;
        }
    };

    var MinAge = function(minAge) {
        if (arguments.length > 0) {
            _minAge = minAge;
        } else {
            return _minAge;
        }
    };

    var MaxAge = function(maxAge) {
        if (arguments.length > 0) {
            _maxAge = maxAge;
        } else {
            return _maxAge;
        }
    };

    var PostalCode = function(postalCode) {
        if (arguments.length > 0) {
            _postalCode = postalCode;
        } else {
            return _postalCode;
        }
    };

    var PostalCodeRadius = function(postalCodeRadius) {
        if (arguments.length > 0) {
            _postalCodeRadius = postalCodeRadius;
        } else {
            return _postalCodeRadius;
        }
    };

    var CountryCode = function(countryCode) {
        if (arguments.length > 0) {
            _countryCode = countryCode;
        } else {
            return _countryCode;
        }
    };

    var PhotoOnly = function(photoOnly) {
        if (arguments.length > 0) {
            _photoOnly = photoOnly;
        } else {
            return _photoOnly;
        }
    };

    var OptedInOnly = function(optedInOnly) {
        if (arguments.length > 0) {
            _optedInOnly = optedInOnly;
        } else {
            return _optedInOnly;
        }
    };

    var ResultCount = function(resultCount) {
        if (arguments.length > 0) {
            _resultCount = resultCount;
        } else {
            return _resultCount;
        }
    };

    var ProfileUrl = function(profileUrl) {
        if (arguments.length > 0) {
            _profileUrl = profileUrl;
        } else {
            return _profileUrl;
        }
    };

    var getDefaultMinAge = function() {
        switch (_siteId) {
        case 161:
        case 164:
        case 166:
            return 45;
        case 101:
        case 105:
        case 167:
            return 50;
        default:
            return 25;
        }
    };

    var getDefaultMaxAge = function() {
        switch (_siteId) {
        case 110:
            return 50;
        case 166:
            return 55;
        case 101:
        case 161:
        case 164:
            return 65;
        case 105:
        case 167:
            return 75;
        default:
            return 35;
        }
    };

    var loadPhotoContainer = function() {
        try {
            PeopleMedia.CP.Messaging.reset();
            reset();
            retrievePhotos(
                function(data) {
                    var $template = $jq("#" + PhotoTemplateId());
                    var $container = $jq('#' + PhotoContainerId());
                    var templateHtml = $template.html();
                    var html = "";
                    for (var i = 0; i < data.length; ++i) {
                        html += getItemHTML(data[i], templateHtml);
                    }
                    $container.append(html);
                },
                function(errors) {
                    var firstError = PeopleMedia.CP.getFirstError(errors);
                    if (!firstError || !firstError.IsAjaxError) {
                        PeopleMedia.CP.Messaging.show(errors);
                    }
                });
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "loadPhotoContainer" });
        }
    };

    var getItemHTML = function(item, templateHtml) {
        try {
            var gender = item.Gender === 1 ? "Male" : "Female";
            var age = item.Age;
            var ageString = (typeof (age) !== 'undefined' && age !== null && age > 0) ? age.toString() : "";
            var useSSL = "https:" === document.location.protocol;

            var primaryImageUrl = null;
            if (typeof (item.PrimaryPhoto) !== 'undefined' && item.PrimaryPhoto !== null && typeof (item.PrimaryPhoto.RootUrl) !== 'undefined' && item.PrimaryPhoto.RootUrl !== null) {
                primaryImageUrl = (useSSL ? "https:" : "http:") + item.PrimaryPhoto.RootUrl;
                var imageType = ImageType() || "t";
                primaryImageUrl += imageType + ".jpg";
            } else {
                primaryImageUrl = (useSSL ? "https://" : "http://") +
                    "pmi0.peoplemedia.com/" +
                    PeopleMedia.CP.SiteID() +
                    "/stock/noPhoto-" +
                    item.Gender +
                    ImageType() +
                    ".gif";
            }
            var profileUrl = ProfileUrl();
            if (typeof (profileUrl) === 'undefined' || profileUrl === null || profileUrl.length === 0) {
                profileUrl = "/v3/profile?profile=" + item.EncodedMemberId;
            }
            profileUrl = profileUrl.replace(/@MEMBER_ID@/g, item.Identifier);
            var html = templateHtml || "";
            html = html.replace(/@ENCODED_MEMBER_ID@/g, item.EncodedMemberId);
            html = html.replace(/@USERNAME@/g, item.Username);
            html = html.replace(/@AGE@/g, ageString);
            html = html.replace(/@GENDER@/g, gender);
            html = html.replace(/@CITY@/g, (typeof (item.Location) !== 'undefined' && item.Location !== null && typeof (item.Location.City) !== 'undefined' && item.Location.City !== null) ? item.Location.City : "");
            html = html.replace(/@STATE_CODE@/g, (typeof (item.Location) !== 'undefined' && item.Location !== null && typeof (item.Location.StateProvince) !== 'undefined' && item.Location.StateProvince !== null) ? item.Location.StateProvince : "");
            html = html.replace(/@COUNTRY_CODE@/g, (typeof (item.Location) !== 'undefined' && item.Location !== null && typeof (item.Location.CountryCode) !== 'undefined' && item.Location.CountryCode !== null) ? item.Location.CountryCode : "");
            html = html.replace(/(http|https):\/\/@PROFILE_URL@/g, profileUrl);
            html = html.replace(/(http|https):\/\/@PHOTO_URL@/g, primaryImageUrl);
            html = html.replace(/data-src=/g, "src="); // Keynote is showing 404 errors on img src="http://@PHOTO_URL@"
            return html;
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "getItemHTML" });
            return "";
        }
    };

    var retrievePhotos = function(onSuccess, onFailure) {
        try {
            var gender = null;
            if (!isNullOrEmpty(Gender())) {
                if (Gender() === '1' || Gender() === 1 || Gender().toString().toLowerCase() === 'm' || Gender().toString().toLowerCase() === 'male') {
                    gender = 1;
                } else if (Gender() === '2' || Gender() === 2 || Gender().toString().toLowerCase() === 'f' || Gender().toString().toLowerCase() === 'female') {
                    gender = 2;
                } else {
                    onFailure([{ "Text": "Invalid Gender" }]);
                    return;
                }
            }

            if (!isNullOrEmpty(MinAge())) {
                var minAge = parseInt(MinAge(), 10);
                if (isNaN(minAge) || minAge < 0 || minAge > 255) {
                    onFailure([{ "Text": "Invalid Min Age" }]);
                    return;
                }
            }

            if (!isNullOrEmpty(MaxAge())) {
                var maxAge = parseInt(MaxAge(), 10);
                if (isNaN(maxAge) || maxAge < 0 || maxAge > 255) {
                    onFailure([{ "Text": "Invalid Max Age" }]);
                    return;
                }
            }

            if (!isNullOrEmpty(PostalCode()) || !isNullOrEmpty(PostalCodeRadius())) {
                if (isNullOrEmpty(PostalCode()) || isNullOrEmpty(PostalCodeRadius())) {
                    onFailure([{ "Text": "For postal code search, postal code and radius must be provided" }]);
                    return;
                }
                if (isNullOrEmpty(CountryCode())) {
                    if (PostalCode().length > 5) {
                        CountryCode("CA");
                    } else {
                        CountryCode("US");
                    }
                }
            }
            if (!isNullOrEmpty(PhotoOnly())) {
                if (PhotoOnly().toLowerCase() !== "true" && PhotoOnly().toLowerCase() !== "false") {
                    onFailure([{ "Text": "Invalid Photo Only value" }]);
                    return;
                }
            }

            if (!isNullOrEmpty(OptedInOnly())) {
                if (OptedInOnly().toLowerCase() !== "true" && OptedInOnly().toLowerCase() !== "false") {
                    onFailure([{ "Text": "Invalid Opted In value" }]);
                    return;
                }
            }

            if (!isNullOrEmpty(ResultCount())) {
                var resultCount = parseInt(ResultCount(), 10);
                if (isNaN(resultCount) || resultCount < 0 || resultCount > 255) {
                    onFailure([{ "Text": "Invalid Result Count" }]);
                    return;
                }
            }

            PeopleMedia.CP.ui_busy();

            var url = "/v3/lps/api/ExternalSearchPhoto";
            var ajaxData = {
                "Gender": gender,
                "MinimumAge": (!isNullOrEmpty(MinAge())) ? MinAge() : getDefaultMinAge(),
                "MaximumAge": (!isNullOrEmpty(MaxAge())) ? MaxAge() : getDefaultMaxAge(),
                "ZipCode": (!isNullOrEmpty(PostalCode())) ? PostalCode() : null,
                "CountryCode": (!isNullOrEmpty(CountryCode())) ? CountryCode() : null,
                "Page": 1,
                "PageSize": (!isNullOrEmpty(ResultCount())) ? ResultCount() : null
            };

            var startTime = new Date().getTime();
            var timeoutValue = 30000;
            jQuery.ajax({
                headers: PeopleMedia.CP.LpsPostbackHeader(),
                type: "POST",
                url: url,
                data: JSON.stringify(ajaxData),
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                success: function(data) {
                    retrievePhotos_complete(onSuccess, onFailure, data);
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    var endTime = new Date().getTime();
                    var duration = (endTime - startTime);
                    var data = PeopleMedia.CP.convertAjaxErrorToFault(jqXHR, textStatus, errorThrown);
                    if (!data.IsCancelled) {
                        PeopleMedia.CP.logAjax({ oid: Oid, method: "retrievePhotos", url: url, duration: duration, jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown });
                    }
                    retrievePhotos_complete(onSuccess, onFailure, data);
                },
                async: true,
                cache: false,
                timeout: timeoutValue
            });
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "retrievePhotos" });
        }
    };

    var retrievePhotos_complete = function(onSuccess, onFailure, data) {
        try {
            var errors = [];
            PeopleMedia.CP.ui_done();
            if (data.Fault) {
                if (!data.Fault.IsCancelled) {
                    errors.push(PeopleMedia.CP.convertFaultToError(data.Fault));
                }
                onFailure(errors);
            } else {
                var photoMembers = Array.isArray(data.Members) ? data.Members : [];
                Members(photoMembers);
                onSuccess(photoMembers);
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "retrievePhotos_complete" });
        }
    };

    var reset = function() {
        try {
            Members([]);
            if (typeof (PhotoContainerId) !== 'undefined' && PhotoContainerId !== null) {
                $jq('#' + PhotoContainerId()).html("");
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "reset" });
        }
    };

    var isNullOrEmpty = function(obj) {
        try {
            if (typeof(obj) === 'undefined' || obj === null || obj === '') {
                return true;
            } else if (typeof (obj) === "string") {
                return (obj || "").trim().length <= 0;
            } else {
                return false;
            }
        } catch (ex) {
            PeopleMedia.CP.handleException(ex, { oid: Oid, method: "isNullOrEmpty" });
            return true;
        }
    };

    return {
        Oid: Oid,
        SiteId: SiteId,
        Members: Members,
        PhotoContainerId: PhotoContainerId,
        PhotoTemplateId: PhotoTemplateId,
        ImageType: ImageType,
        Gender: Gender,
        MinAge: MinAge,
        MaxAge: MaxAge,
        PostalCode: PostalCode,
        PostalCodeRadius: PostalCodeRadius,
        CountryCode: CountryCode,
        PhotoOnly: PhotoOnly,
        OptedInOnly: OptedInOnly,
        ResultCount: ResultCount,
        ProfileUrl: ProfileUrl,
        getDefaultMinAge: getDefaultMinAge,
        getDefaultMaxAge: getDefaultMaxAge,
        loadPhotoContainer: loadPhotoContainer,
        getItemHTML: getItemHTML,
        retrievePhotos: retrievePhotos,
        retrievePhotos_complete: retrievePhotos_complete,
        reset: reset,
        isNullOrEmpty: isNullOrEmpty
    };
})(jQuery);

// for backwards compatibility only
PeopleMedia.CP.PhotoFeed = function () {
};

// for backwards compatibility only
PeopleMedia.CP.PhotoFeed.prototype = {
    Oid: PeopleMedia.CP.PhotoSearch.Oid,
    SiteId: PeopleMedia.CP.PhotoSearch.SiteId,
    Members: PeopleMedia.CP.PhotoSearch.Members,
    PhotoContainerId: PeopleMedia.CP.PhotoSearch.PhotoContainerId,
    PhotoTemplateId: PeopleMedia.CP.PhotoSearch.PhotoTemplateId,
    ImageType: PeopleMedia.CP.PhotoSearch.ImageType,
    Gender: PeopleMedia.CP.PhotoSearch.Gender,
    MinAge: PeopleMedia.CP.PhotoSearch.MinAge,
    MaxAge: PeopleMedia.CP.PhotoSearch.MaxAge,
    PostalCode: PeopleMedia.CP.PhotoSearch.PostalCode,
    PostalCodeRadius: PeopleMedia.CP.PhotoSearch.PostalCodeRadius,
    CountryCode: PeopleMedia.CP.PhotoSearch.CountryCode,
    PhotoOnly: PeopleMedia.CP.PhotoSearch.PhotoOnly,
    OptedInOnly: PeopleMedia.CP.PhotoSearch.OptedInOnly,
    ResultCount: PeopleMedia.CP.PhotoSearch.ResultCount,
    ProfileUrl: PeopleMedia.CP.PhotoSearch.ProfileUrl,
    getDefaultMinAge: PeopleMedia.CP.PhotoSearch.getDefaultMinAge,
    getDefaultMaxAge: PeopleMedia.CP.PhotoSearch.getDefaultMaxAge,
    loadPhotoContainer: PeopleMedia.CP.PhotoSearch.loadPhotoContainer,
    getItemHTML: PeopleMedia.CP.PhotoSearch.getItemHTML,
    retrievePhotos: PeopleMedia.CP.PhotoSearch.retrievePhotos,
    retrievePhotos_complete: PeopleMedia.CP.PhotoSearch.retrievePhotos_complete,
    reset: PeopleMedia.CP.PhotoSearch.reset,
    isNullOrEmpty: PeopleMedia.CP.PhotoSearch.isNullOrEmpty
};
